import axios, { AxiosInstance, AxiosError, AxiosResponse } from "axios";
import parse from "set-cookie-parser";
import { serialize } from "cookie";
// import { Logger } from "chingari-components";
import { IncomingMessage, ServerResponse } from "http";
import { Config } from "./config";
import { sendLogs } from "./helper";

// const logger = Logger(sendLogs);

export const determineBaseURL = (defaultURL: string, host: string): string => {
  // Add conditions to set different base URLs based on different hostnames
  let newUrl = defaultURL;
  const domain = host?.split(".").slice(-2).join(".");
  const defaultUrlDomain = newUrl?.split(".").slice(-2).join(".");
  if (domain && defaultUrlDomain && !host.includes("localhost")) {
    newUrl = newUrl.replace(defaultUrlDomain, domain);
  }

  // Add more conditions as needed

  // Default to the provided baseURL if no matching condition is found
  return newUrl;
};

// Create an Axios instance
const axiosInstance: AxiosInstance = axios.create({
  baseURL: Config.Backend_Url,
  headers: {
    "Content-Type": "application/json",
    platform: "web",
  },
  withCredentials: true,
});

export const createInstance = (req: IncomingMessage) => {
  axiosInstance.defaults.baseURL = determineBaseURL(
    axiosInstance.defaults.baseURL || "",
    req.headers.host ?? ""
  );
};

// Request interceptor to log requests
axiosInstance.interceptors.request.use(
  (config) => {
    if (typeof window !== "undefined") {
      const baseURL = determineBaseURL(
        config.baseURL || "",
        window.location.host ?? ""
      );
      config.baseURL = baseURL;
    }
    config.headers.platform = "web";
    // config.params['token']='abcd';
    // logger.info(config, "Request Page");
    return config;
  },
  (error: AxiosError) => {
    // logger.error("Request error", "Error Page");
    return Promise.reject(error);
  }
);

// Response interceptor to log responses
axiosInstance.interceptors.response.use(
  (response) => {
    if (!response?.request?.responseURL?.includes("astro/free-kundli")) {
      // logger.success(response.data, "Response Page");
    }
    return response;
  },
  (error) => {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        // logger.error(error.response.data, "Error Page");
      } else if (error.request) {
        // logger.error("No response received", "Error Page");
      } else {
        // logger.error("Request failed", "Error Page");
      }
    } else {
      // logger.error("An error occurred", "Error Page");
    }
    return Promise.reject(error);
  }
);

export const setCookie = (respData: AxiosResponse, res?: ServerResponse) => {
  try {
    if (res) {
      const setCookieHeader = respData?.headers?.["set-cookie"] || "";
      const cookies = parse(setCookieHeader);

      const serializedCookies = cookies.map(function (cookie) {
        return serialize(cookie.name, cookie.value, {
          ...cookie,
          sameSite: true,
          domain: "." + res?.req?.headers?.host?.split(".").slice(-2).join("."),
        });
      });

      res?.setHeader("set-cookie", serializedCookies);
    }
  } catch (e) {
    console.error(e);
    console.log("error while setting cookies");
    throw e;
  }
};

export default axiosInstance;
