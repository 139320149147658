"use client";
import { Caller, ContentOwner } from "../utils/call-service.types";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from "react";
import TRTC from "trtc-sdk-v5";
import {
  AuthType,
  ProfileDataInterface,
  ToastDataType,
} from "../utils/common.types";
import { Socket } from "socket.io-client";
// import { WalletBalanceItem } from "@/modules/RoomContainer/types";
export interface PrivateCallContextData {
  channelId?: string;
  onCall?: boolean;
  callPopup?: boolean;
  buyPopup?: boolean;
  caller?: Caller;
  contentOwner?: ContentOwner;
  lowDiamondPopup?: boolean;
  duration?: number;
  reviewPopup?: boolean;
  purchasePlan?: string;
  topUpAmount?: number;
  insufficientBalancePopup?: boolean;
}

export interface GlobalPopups {
  showLoginPopup?: boolean;
  isProfilePopup?: boolean;
  downloadPopup?: boolean;
  logoutPopup?: boolean;
}

export interface AppContextProps {
  loader: boolean;
  setLoader: (val: boolean) => void;
  auth: AuthType;
  setAuth: (data: AuthType) => void;
  nonAuth: string;
  setNonAuth: React.Dispatch<React.SetStateAction<string>>;
  popups: GlobalPopups;
  setPopups: (data: GlobalPopups) => void;
  userData: ProfileDataInterface | null;
  setUserData: (data: ProfileDataInterface | null) => void;
  trtcClient: TRTC | null;
  setTrtcClient: (val: TRTC) => void;
  privateCallData: PrivateCallContextData;
  setPrivateCallData: (data: PrivateCallContextData) => void;
  ToastData: ToastDataType;
  setToastData: React.Dispatch<React.SetStateAction<ToastDataType>>;
  // walletBalance: WalletBalanceItem;
  // setWalletBalance: React.Dispatch<React.SetStateAction<WalletBalanceItem>>;
  categoryHistory: string;
  setCategoryHistory: React.Dispatch<React.SetStateAction<string>>;
  privateCallSocket: Socket | null;
  setPrivateCallSocket: Dispatch<SetStateAction<Socket | null>>;
  isCallScreen: boolean;
  setIsCallScreen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppContext = createContext<AppContextProps | null>(null);

const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [auth, setAuth] = useState<AuthType>({});
  const [nonAuth, setNonAuth] = useState<string>("");
  const [userData, setUserData] = useState<ProfileDataInterface | null>(null);
  const [trtcClient, setTrtcClient] = useState<TRTC | null>(null);
  const [popups, setPopups] = useState<GlobalPopups>({
    showLoginPopup: false,
    isProfilePopup: false,
    downloadPopup: false,
    logoutPopup: false,
  });

  const [privateCallData, setPrivateCallData] =
    useState<PrivateCallContextData>({
      onCall: false,
      callPopup: false,
      buyPopup: false,
      insufficientBalancePopup: false,
      purchasePlan: "",
      topUpAmount: 0,
    });
  const [ToastData, setToastData] = useState<ToastDataType>({});
  // const [walletBalance, setWalletBalance] = useState<WalletBalanceItem>({});
  const [categoryHistory, setCategoryHistory] = useState<string>("");
  const [privateCallSocket, setPrivateCallSocket] = useState<Socket | null>(
    null
  );
  const [isCallScreen, setIsCallScreen] = useState<boolean>(false);

  return (
    <AppContext.Provider
      value={{
        loader,
        setLoader,
        auth,
        setAuth,
        nonAuth,
        setNonAuth,
        popups,
        setPopups,
        userData,
        setUserData,
        trtcClient,
        setTrtcClient,
        privateCallData,
        setPrivateCallData,
        ToastData,
        setToastData,
        // walletBalance,
        // setWalletBalance,
        categoryHistory,
        setCategoryHistory,
        privateCallSocket,
        setPrivateCallSocket,
        isCallScreen,
        setIsCallScreen,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
