import axios, { AxiosResponse } from "axios";
import { GET_NON_AUTH_TOKEN } from "../utils/apiEndPoints";
import { Config } from "../utils/config";
import axiosInstance from "@/utils/customAxios";
import {
  getDiamondPlansResponseData,
  ProfileDataInterface,
  REQUEST_METHOD,
} from "@/utils/common.types";

const Axios = axios.create({
  baseURL: Config.BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export async function getNonAuthToken() {
  try {
    const data: AxiosResponse<string> = await Axios.get(
      `${Config.BACKEND_URL}${GET_NON_AUTH_TOKEN}`
    );
    return data?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getUserData(userId = "", token?: string) {
  try {
    const data = await axiosInstance<AxiosResponse<ProfileDataInterface>>({
      method: REQUEST_METHOD.GET,
      url: `users/${userId}/profile`,
      params: { token },
    });
    if (data?.status === 200) return data?.data?.data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function getDiamondPlans(token?: string) {
  try {
    const data = await axiosInstance<getDiamondPlansResponseData>({
      method: REQUEST_METHOD.GET,
      url: `/diamond-history/plans`,
      params: { token },
    });
    if (data?.status === 200) return data?.data?.plans;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export default Axios;
